//@ts-ignore
import plugin0 from "fireside-plugin-component-gap"
//@ts-ignore
import plugin1 from "@kaminrunde/fireside-plugin-ab-test"
//@ts-ignore
import plugin2 from "@kaminrunde/fireside-plugin-copy-component"


export default {
  "fireside-plugin-component-gap": plugin0,
  "@kaminrunde/fireside-plugin-ab-test": plugin1,
  "@kaminrunde/fireside-plugin-copy-component": plugin2,
}